.loader-container {
  margin-left: 2%;
  margin-top: 6%;
}

@keyframes scaling {
  0%,
  100% {
    transform: scale(0.2);
   
    background-color:  #69AC96;
  }
  40% {
    transform: scale(1);
    background-color: #65D8B5;
  }
  50% {
    transform: scale(1);
    background-color:  #AEF8DC;

  }
}

.loader-container div {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  transform: scale(0);
  background-color: red;
  animation: scaling 2.5s ease-in-out infinite;
  display: inline-block;
  margin: 3px;
  // opacity: 0.5;
}

.loader-container div:nth-of-type(1) {
  animation-delay: 0s;
}
.loader-container div:nth-of-type(2) {
  animation-delay: 0.2s;
}
.loader-container div:nth-of-type(3) {
  animation-delay: 0.4s;
}
.loader-container div:nth-of-type(4) {
  animation-delay: 0.6s;
}
.loader-container div:nth-of-type(5) {
  animation-delay: 0.8s;
}
